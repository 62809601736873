import React, { KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';

import MuiTextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import { fieldToTextField, TextFieldProps } from 'formik-material-ui';
import { useDebouncedCallback } from 'use-debounce';

import { checkIOS } from '../../helpers/checkios';
import { useScreenSize } from '../../hooks/use-screen-size';

import { StyleProps, useStyles } from './style';

interface Props extends StyleProps {
  hideWarning?: boolean;
  customError?: string;
  decimals?: number;
  checkIsValueExists?: (exist: boolean) => void;
  skipDebounce?: boolean;
}

export const FormikInput: React.FC<TextFieldProps & Props> = ({
  hideWarning,
  decimals = 2,
  customError,
  checkIsValueExists,
  inputFontSize,
  height,
  skipDebounce,
  ...props
}) => {
  const {
    form: { setFieldValue, setTouched, setErrors },
    field: { name, value },
    label,
    placeholder,
    multiline,
  } = props;
  const classes = useStyles({ height, inputFontSize });
  const hiddenDiv = useRef<HTMLInputElement | null>(null);
  const { isMobile } = useScreenSize();

  const [innerValue, setInnerValue] = useState('');

  const debouncedHandleOnChange = useDebouncedCallback(
    (fieldValue: string) => {
      setFieldValue(name, fieldValue);
    },
    skipDebounce ? 0 : 300,
  );

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();
      if (props.onChange instanceof Function) {
        props.onChange(event);
      }
      if (decimals !== undefined && !isNaN(+event.currentTarget.value) && event.target.value.includes('.')) {
        const [integer, dec] = event.target.value.split('.');
        const valueToSet = integer + '.' + (dec as string).substr(0, decimals);
        setInnerValue(valueToSet);
        debouncedHandleOnChange(valueToSet);
      } else {
        setInnerValue(event.currentTarget.value);
        debouncedHandleOnChange(event.currentTarget.value);
      }
    },
    [debouncedHandleOnChange],
  );

  const onEnterPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && hiddenDiv && isMobile) {
      e.currentTarget.blur();
      hiddenDiv.current?.focus();
    }
  };

  useEffect(() => {
    setInnerValue(value || '');
  }, [value]);

  useEffect(() => {
    if (customError) {
      setTouched({ [name]: true }, false);
      setErrors({ [name]: customError });
    }
  }, [customError]);

  useEffect(() => {
    if (!checkIsValueExists) {
      return;
    }
    if (!innerValue) {
      checkIsValueExists(false);
      return;
    }
    if (innerValue && +innerValue === 0) {
      checkIsValueExists(false);
      return;
    }
    checkIsValueExists(true);
  }, [checkIsValueExists, innerValue]);

  return (
    <div className={clsx([classes.box, label && classes.paddingBox])}>
      <MuiTextField
        autoComplete={props.autoComplete || 'off'}
        type={props.type || 'search'}
        {...fieldToTextField(props)}
        value={innerValue}
        onChange={handleOnChange}
        placeholder={placeholder}
        onKeyUp={onEnterPress}
        classes={{ root: classes.rootField }}
        inputMode={props.type === 'number' && checkIOS() ? 'decimal' : undefined}
        InputLabelProps={{
          shrink: true,
        }}
        FormHelperTextProps={{
          className: clsx([classes.toolTip, hideWarning && classes.hideWarning, multiline && classes.toolTipMultiline]),
        }}
        InputProps={{
          className: clsx([classes.root, multiline && classes.rootMultiline]),
        }}
      />

      {isMobile && (
        <input
          className={classes.hidden}
          ref={hiddenDiv}
          onFocus={() => {
            hiddenDiv.current?.blur();
          }}
        />
      )}
    </div>
  );
};
