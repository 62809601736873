import React, { ChangeEvent, useEffect, useState } from 'react';

import { InputLabel, ListItemText, MenuItem, Select } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import { SelectProps } from 'formik-material-ui';

import { StyleProps, useStyles } from './style';

interface Props extends StyleProps {
  options: { label: string | React.ReactNode; value: string; logo?: string }[];
  placeholder?: string;
  unSelectText?: string;
  submitOnChange?: boolean;
  customClasses?: {
    customSelectClass: string;
    customFormControlClass: string;
    placeholderClass: string;
  };
}

export const FormikDropdown: React.FC<Props & SelectProps> = (props) => {
  const {
    form: { setFieldValue, isValid, errors, submitForm },
    field: { name, value },
    label,
    options,
    placeholder,
    inputFontSize,
    labelFontSize,
    height,
    labelColor,
    unSelectText,
    submitOnChange,
    customClasses,
    disabled,
  } = props;

  const classes = useStyles({
    inputFontSize,
    labelFontSize,
    height,
    labelColor,
  });
  const [selected, setSelected] = useState<string>('');

  const handleChange = (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    event.persist();
    const selectedValue = event.target.value as string;
    setSelected(selectedValue || '');
    setFieldValue(name, selectedValue || '');
    submitOnChange && submitForm();
  };

  useEffect(() => {
    value && value !== selected && setSelected(value);
  }, [value]);

  return (
    <div className={classes.box}>
      {label && <InputLabel classes={{ root: classes.label }}>{label}</InputLabel>}
      {!isValid && errors[name] && <div className={classes.toolTip}>{errors[name]}</div>}
      <FormControl
        variant='outlined'
        classes={{
          root: customClasses?.customFormControlClass
            ? clsx(classes.rootFieldDefault, customClasses.customFormControlClass)
            : classes.rootField,
        }}
      >
        {(!selected || !value?.trim()) && (
          <div
            className={customClasses?.placeholderClass ? clsx(classes.placeholder, customClasses.placeholderClass) : classes.placeholder}
          >
            {placeholder}
          </div>
        )}
        <Select
          inputProps={{
            classes: {
              icon: customClasses ? classes.iconDefault : classes.icon,
            },
          }}
          IconComponent={ExpandMoreIcon}
          classes={{
            root: customClasses?.customSelectClass || classes.select,
          }}
          value={selected}
          disabled={disabled}
          onChange={handleChange}
          MenuProps={{
            anchorOrigin: {
              vertical: 40,
              horizontal: 0,
            },
            getContentAnchorEl: null,
            disableScrollLock: true,
          }}
        >
          {unSelectText ? (
            <MenuItem classes={{ root: clsx([classes.menuItem, classes.unselect]) }}>{unSelectText}</MenuItem>
          ) : (
            <MenuItem classes={{ root: classes.empty }} />
          )}
          {options.map(({ label: optionLabel, value: optionValue, logo }) => (
            <MenuItem
              key={typeof optionLabel === 'string' ? optionLabel : optionValue}
              value={optionValue}
              classes={{
                root: classes.menuItem,
                selected: classes.selected,
              }}
            >
              {logo && <img src={logo} className={classes.logo} />}
              <ListItemText primary={optionLabel} className={classes.itemText} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
