import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { PromiseWithKnownReason } from '@reduxjs/toolkit/dist/query/core/buildMiddleware/types';
import { QueryFulfilledRejectionReason } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { format } from 'date-fns';
import isEqual from 'lodash/isEqual';

import { salesSortParams } from '../components/AppDrawer/RestaurantDrawer/Tools/SalesWastage/SalesPage';
import { wastagesSortParams } from '../components/AppDrawer/RestaurantDrawer/Tools/SalesWastage/WastagePage';
import { RangeOptions } from '../shared/components/range-dropdown';
import { getCategoryId } from '../shared/helpers/getCategoryId';
import { getDaysFromRange } from '../shared/helpers/getDaysFromRange';
import { setAppSuccessToast, setSuccessMsg } from '../store/user';
import { AdminConnectionsListQueryParams, GetConnectionsListReqParams } from './admin-connections/types';
import { AdminDiscountsListQueryParams, GetDiscountsListReqParams } from './admin-discounts/types';
import { AdminEmailsListQueryParams, GetEmailsListReqParams } from './admin-emails/types';
import { AdminErrorsListQueryParams, GetErrorsListReqParams } from './admin-errors/types';
import { AdminOrdersListQueryParams, GetOrdersListReqParams as AdminGetOrdersListReqParams } from './admin-orders/types';
import { GetOverdueListReqParams, OverdueListQueryParams } from './admin-overdue-exports/types';
import { AdminProductsListQueryParams, GetProductListReqParams } from './admin-products/types';
import { RestaurantsListReqParams, RestaurantsQueryParams } from './admin-restaurants/types';
import { SuppliersListReqParams, SuppliersQueryParams } from './admin-suppliers/types';
import { UsersListReqParams, UsersQueryParams } from './admin-users/types';
import { CustomerFilterValues, CustomerQueryParams, CustomersSortBy } from './customer/types';
import {
  CustomerPerformanceQueryParams,
  GetCustomerPerformanceReqParams,
  GetProductsPurchasedListReqParams,
  ProductsPurchasedListQueryParams,
} from './dashboard/types';
import { DeliveryListQueryParams, GetDeliveryListReqParams } from './delivery/types';
import { DriversListQueryParams, GetDriversListReqParams } from './drivers/types';
import { BaseQueryFn, SuccessResponse } from './index';
import { GetInventoryListReqParams, InventoryListQueryParams } from './inventory/types';
import { GetRecipesRequest } from './menu/types';
import { GetOrdersListReqParams, OrdersListQueryParams } from './order/types';
import { GetPantryListReqParams, PantryListQueryParams } from './pantry-list/types';
import { GetPriceExceptionReqParams, PriceExceptionQueryParams } from './price-exception/types';
import { ProductFilterValues, ProductQueryParams, ProductsSortBy } from './product/types';
import { FrequencyEnum, GetRecOrdersReqParams, RecOrdersQueryParams } from './recurring-order/types';
import { GetSalesReqParams, SalesQueryParams } from './sales/types';
import { GetTeammatesListReqParams, TeammatesListQueryParams } from './teammates/types';
import { Roles } from './user/types';
import { GetWastagesReqParams, WastageQueryParams } from './wastages/types';
import { GetSSInventoryListReqParams, SSInventoryListQueryParams } from './supplier-inventory/types';

export const setSuccessMsgHelper = async (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  queryFulfilled: PromiseWithKnownReason<
    { data: SuccessResponse; meta: {} | undefined },
    QueryFulfilledRejectionReason<BaseQueryFn<any, unknown, { data: { message: any; success: boolean } }>>
  >,
  message: string,
) => {
  try {
    const { data } = await queryFulfilled;
    if (data?.success) {
      dispatch(
        setSuccessMsg({
          message,
        }),
      );
    }
  } catch (e) {
    console.log(e);
  }
};

export const setSuccessToast = async (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  queryFulfilled: PromiseWithKnownReason<
    { data: SuccessResponse; meta: {} | undefined },
    QueryFulfilledRejectionReason<BaseQueryFn<any, unknown, { data: { message: any; success: boolean } }>>
  >,
  message: string,
) => {
  try {
    const { data } = await queryFulfilled;
    if (data?.success) {
      dispatch(setAppSuccessToast(message));
    }
  } catch (e) {
    console.log(e);
  }
};

export const getProductQueryParamsHelper = (sort?: ProductsSortBy, filters?: ProductFilterValues): ProductQueryParams => {
  const params: ProductQueryParams = {};
  if (sort && Array.isArray(sort)) {
    params['q[sort_by]'] = sort.join(' ');
  } else {
    params['q[sort_by]'] = 'name asc';
  }
  if (!filters) {
    return params;
  }
  if (filters.status?.includes('archived_product')) {
    params['q[archived_product]'] = 'true';
  }
  if (filters.status?.includes('active_product')) {
    params['q[active_product]'] = 'true';
  }
  if (filters.gst) {
    params['q[gst]'] = JSON.stringify(filters.gst.map((opt) => Boolean(+opt)));
  }
  if (filters.stock) {
    params['q[stock]'] = JSON.stringify(filters.stock);
  }
  if (filters.min_cost) {
    params['q[min_cost]'] = filters.min_cost;
  }
  if (filters.max_cost) {
    params['q[max_cost]'] = filters.max_cost;
  }
  return params;
};

export const getManualProductQueryParamsHelper = (sort?: ProductsSortBy): ProductQueryParams => {
  const params: ProductQueryParams = {};
  if (sort && Array.isArray(sort)) {
    params['q[sort_by]'] = sort.join(' ');
  }
  return params;
};

export const getCustomersListQueryParamsHelper = (
  sort?: CustomersSortBy,
  filters?: CustomerFilterValues,
  keyword?: string,
): CustomerQueryParams => {
  const params: CustomerQueryParams = {};
  if (sort && Array.isArray(sort)) {
    params['q[sort_by]'] = sort.join(' ');
  }
  if (keyword) {
    params['q[keyword]'] = keyword;
  }
  if (!filters) {
    return params;
  }
  if (filters.status_in) {
    params['q[status_in]'] = JSON.stringify(filters.status_in);
  }
  if (filters.user_price_lists_name) {
    params['q[user_price_lists_name]'] = JSON.stringify(filters.user_price_lists_name);
  }
  return params;
};

export const getPantryListQueryParamsHelper = ({ keyword, sort, filter }: GetPantryListReqParams): PantryListQueryParams => {
  const params: PantryListQueryParams = {};

  if (keyword) {
    params['q[keyword]'] = keyword;
  }
  if (sort && Array.isArray(sort)) {
    params['q[sort_by]'] = sort.join(' ');
  } else {
    params['q[sort_by]'] = 'created_at desc';
  }
  if (filter) {
    params['q[category_id]'] = getCategoryId(filter);
  }

  return params;
};

export const getSalesQueryParamsHelper = ({ sortBy, filter, keyword }: GetSalesReqParams): SalesQueryParams => {
  const params: SalesQueryParams = {};

  if (sortBy && Array.isArray(sortBy)) {
    params['q[sort_by]'] = sortBy.join(' ');
  } else {
    params['q[sort_by]'] = salesSortParams[0].value;
  }
  if (filter?.menu) {
    params['q[menu_name]'] = filter.menu;
  }
  if (keyword) {
    params['q[keyword]'] = keyword;
  }
  if (filter?.date) {
    params['q[sale_date]'] = filter.date;
  }
  if (filter?.selectedRange && filter?.selectedRange !== RangeOptions.CUSTOM && filter?.selectedRange !== RangeOptions.ALL_TIME) {
    const days = getDaysFromRange(filter.selectedRange);
    days === 1 ? (params['q[sale_date]'] = new Date().toString()) : (params['q[days]'] = days);
  }
  if (filter?.fromDate && filter?.toDate && (!filter?.selectedRange || filter?.selectedRange === RangeOptions.CUSTOM)) {
    params['q[from_date]'] = filter.fromDate;
    params['q[to_date]'] = filter.toDate;
  }

  return params;
};

export const getWastageQueryParamsHelper = ({ sortBy, filter, keyword }: GetWastagesReqParams): WastageQueryParams => {
  const params: WastageQueryParams = {};

  if (sortBy && Array.isArray(sortBy)) {
    params['q[sort_by]'] = sortBy.join(' ');
  } else {
    params['q[sort_by]'] = wastagesSortParams[0].value;
  }
  if (filter?.menu) {
    params['q[menu_name]'] = filter.menu;
  }
  if (keyword) {
    params['q[keyword]'] = keyword;
  }
  if (filter?.date) {
    params['q[wastage_date]'] = filter.date;
  }
  if (filter?.selectedRange && filter?.selectedRange !== RangeOptions.CUSTOM && filter?.selectedRange !== RangeOptions.ALL_TIME) {
    const days = getDaysFromRange(filter.selectedRange);
    days === 1 ? (params['q[wastage_date]'] = new Date().toString()) : (params['q[days]'] = days);
  }
  if (filter?.fromDate && filter?.toDate && (!filter?.selectedRange || filter?.selectedRange === RangeOptions.CUSTOM)) {
    params['q[from_date]'] = filter.fromDate;
    params['q[to_date]'] = filter.toDate;
  }

  return params;
};

export const getRecOrdersQueryParamsHelper = ({ keyword, sort, filter }: GetRecOrdersReqParams): RecOrdersQueryParams => {
  const params: RecOrdersQueryParams = {};

  if (keyword) {
    params['q[keyword]'] = keyword;
  }
  if (sort && Array.isArray(sort)) {
    params['q[sort_by]'] = sort.join(' ');
  } else {
    params['q[sort_by]'] = 'created_at desc';
  }
  if (filter) {
    params['q[category_id]'] = getCategoryId(filter);
  }

  return params;
};

export const getCustomerPerformanceQueryParamsHelper = ({
  keyword,
  sort,
  filter,
}: GetCustomerPerformanceReqParams): CustomerPerformanceQueryParams => {
  const params: CustomerPerformanceQueryParams = {};

  if (keyword) {
    params['q[keyword]'] = keyword;
  }
  if (filter?.risk_sensitivity) {
    params.risk_sensitivity = filter?.risk_sensitivity;
  }
  if (sort && Array.isArray(sort)) {
    params['q[sort_by]'] = sort.join(' ');
  } else {
    params['q[sort_by]'] = 'performance desc';
  }
  if (filter?.days || filter?.days === 0) {
    params.days = filter?.days;
  }

  return params;
};

export const getProductsPurchasedQueryParamsHelper = ({
  keyword,
  sort,
  filter,
}: GetProductsPurchasedListReqParams): ProductsPurchasedListQueryParams => {
  const params: ProductsPurchasedListQueryParams = {};

  if (keyword) {
    params['q[keyword]'] = keyword;
  }
  if (sort && Array.isArray(sort)) {
    params['q[sort_by]'] = sort.join(' ');
  }
  if (filter?.date) {
    params.date = format(new Date(filter.date), 'yyyy-MM-dd');
  } else {
    params.date = format(new Date(), 'yyyy-MM-dd');
  }
  if (filter?.days || filter?.days === 0) {
    params.days = filter?.days;
    params.date = undefined;
  }
  if (filter?.days === 1) {
    params.days = undefined;
    params.date = undefined;
    params['[date_range][from_date]'] = undefined;
    params['[date_range][to_date]'] = undefined;
    return params;
  }
  if (filter?.fromDate && filter.days === undefined) {
    params['[date_range][from_date]'] = filter?.fromDate;
    params.date = undefined;
  }
  if (filter?.toDate && filter.days === undefined) {
    params['[date_range][to_date]'] = filter?.toDate;
    params.date = undefined;
  }

  return params;
};

export const getOrdersListQueryParamsHelper = ({ keyword, filter, sort }: GetOrdersListReqParams): OrdersListQueryParams => {
  const params: OrdersListQueryParams = {};

  if (sort && Array.isArray(sort)) {
    params['q[sort_by]'] = sort.join(' ');
  } else {
    params['q[sort_by]'] = 'created_at desc';
  }
  if (keyword) {
    params['q[keyword]'] = keyword;
  }
  if (filter?.category) {
    params['q[category_id]'] = getCategoryId(filter.category);
  }
  if (filter?.notes) {
    params['q[notes]'] = filter.notes;
  }
  if (filter?.export_status && filter?.export_status.length) {
    params['q[export_status]'] = JSON.stringify(filter.export_status);
  }
  if (filter?.stock && filter.stock.length) {
    params['q[stock]'] = JSON.stringify(filter.stock);
  }
  if (filter?.gst && filter.gst.length) {
    params['q[gst]'] = JSON.stringify(filter.gst.map((opt) => Boolean(+opt)));
  }
  if (filter?.min_total_price) {
    params['q[min_total_price]'] = filter.min_total_price;
  }
  if (filter?.max_total_price) {
    params['q[max_total_price]'] = filter.max_total_price;
  }
  if (filter?.order_from_date) {
    params['q[order_from_date]'] = filter.order_from_date;
  }
  if (filter?.order_to_date) {
    params['q[order_to_date]'] = filter.order_to_date;
  }
  if (filter?.delivery_from_date) {
    params['q[delivery_from_date]'] = filter.delivery_from_date;
  }
  if (filter?.delivery_to_date) {
    params['q[delivery_to_date]'] = filter.delivery_to_date;
  }
  if (filter?.orders_for_delivery_today) {
    params['q[orders_for_delivery_today]'] = true;
  }

  return params;
};

export const getPriceExceptionQueryParamsHelper = ({ keyword, filter, sort }: GetPriceExceptionReqParams): PriceExceptionQueryParams => {
  const params: PriceExceptionQueryParams = {};

  if (keyword) {
    params['q[keyword]'] = keyword;
  }
  if (filter?.product_name) {
    params['q[product_name]'] = filter.product_name;
  }
  if (filter?.from_date) {
    params['q[from_date]'] = filter.from_date;
  }
  if (filter?.to_date) {
    params['q[to_date]'] = filter.to_date;
  }
  if (filter?.min_total_price) {
    params['q[min_total_price]'] = filter.min_total_price;
  }
  if (filter?.max_total_price) {
    params['q[max_total_price]'] = filter.max_total_price;
  }
  if (sort && Array.isArray(sort)) {
    params['q[sort_by]'] = sort.join(' ');
  } else {
    params['q[sort_by]'] = 'user_company_name asc';
  }

  return params;
};

export const getRecOrderFrequency = (frequency: FrequencyEnum): number | undefined => {
  switch (frequency) {
    // case FrequencyEnum.DAYS1:
    //   return 1;
    case FrequencyEnum.DAYS7:
      return 7;
    case FrequencyEnum.DAYS14:
      return 14;
    case FrequencyEnum.DAYS28:
      return 28;
  }
};

export const getUsersListQueryParamsHelper = (args: UsersListReqParams): CustomerQueryParams => {
  const params: UsersQueryParams = {};
  if (args.sort && Array.isArray(args.sort)) {
    params['q[sort_by]'] = args.sort.join(' ');
  } else {
    params['q[sort_by]'] = 'created_at desc';
  }
  if (args.keyword) {
    params['q[keyword]'] = args.keyword;
  }
  if (args.filter?.verified !== undefined) {
    params['q[verified]'] = args.filter.verified;
  }
  if (args.filter?.company_type === 'admin') {
    params['q[is_admin]'] = true;
    return params;
  }
  if (args.filter?.company_type) {
    params['q[company_type]'] = args.filter.company_type === Roles.CUSTOMER ? '1' : '0';
  }
  return params;
};

export const getRestaurantsListQueryParamsHelper = (args: RestaurantsListReqParams): RestaurantsQueryParams => {
  const params: RestaurantsQueryParams = {};
  if (args.sort && Array.isArray(args.sort)) {
    params['q[sort_by]'] = args.sort.join(' ');
  } else {
    params['q[sort_by]'] = 'created_at desc';
  }
  if (args.keyword) {
    params['q[keyword]'] = args.keyword;
  }
  return params;
};

export const getSuppliersListQueryParamsHelper = (args: SuppliersListReqParams): SuppliersQueryParams => {
  const params: SuppliersQueryParams = {};
  if (args.sort && Array.isArray(args.sort)) {
    params.sort_by = args.sort.join(' ');
  } else {
    params.sort_by = 'created_at desc';
  }
  if (args.keyword) {
    params.keyword = args.keyword;
  }
  return params;
};

export const getAdminOrdersListQueryParamsHelper = ({ keyword, filter, sort }: AdminGetOrdersListReqParams): AdminOrdersListQueryParams => {
  const params: AdminOrdersListQueryParams = {};

  if (sort && Array.isArray(sort)) {
    params['q[sort_by]'] = sort.join(' ');
  } else {
    params['q[sort_by]'] = 'created_at desc';
  }
  if (keyword) {
    params['q[keyword]'] = keyword;
  }
  if (filter?.days) {
    params.days = filter?.days;
  }
  if (!filter?.days && !filter?.to_date && !filter?.to_date) {
    params.days = 0;
  }
  if (filter?.to_date && filter?.from_date) {
    params['[date_range][from_date]'] = filter.from_date;
    params['[date_range][to_date]'] = filter.to_date;
    params.days = undefined;
  }

  return params;
};

export const getAdminProductsListQueryParamsHelper = ({ keyword, sort }: GetProductListReqParams): AdminProductsListQueryParams => {
  const params: AdminProductsListQueryParams = {};

  if (sort && Array.isArray(sort)) {
    params['q[sort_by]'] = sort.join(' ');
  }
  if (keyword) {
    params['q[keyword]'] = keyword;
  }

  return params;
};

export const getAdminDiscountsListQueryParamsHelper = ({
  keyword,
  filter,
  sort,
}: GetDiscountsListReqParams): AdminDiscountsListQueryParams => {
  const params: AdminDiscountsListQueryParams = {};

  if (keyword) {
    params['q[keyword]'] = keyword;
  }
  if (sort && Array.isArray(sort)) {
    params['q[sort_by]'] = sort.join(' ');
  }
  if (filter?.tools !== undefined) {
    params['q[tools]'] = filter.tools;
  }
  if (filter?.period !== undefined) {
    params['q[discount_period]'] = filter.period;
  }

  return params;
};

export const getAdminConnectionsListQueryParamsHelper = ({
  keyword,
  sort,
  filter,
}: GetConnectionsListReqParams): AdminConnectionsListQueryParams => {
  const params: AdminConnectionsListQueryParams = {};

  if (sort && Array.isArray(sort)) {
    params['q[sort_by]'] = sort.join(' ');
  }
  if (keyword) {
    params['q[keyword]'] = keyword;
  }
  if (filter?.send_by) {
    params['q[send_by]'] = JSON.stringify([+filter.send_by]);
  }
  if (filter?.status_in && Array.isArray(filter?.status_in)) {
    params['q[status_in]'] = JSON.stringify(filter.status_in.includes('0') ? [...filter.status_in, '2'] : filter.status_in);
  }

  return params;
};

export const getAdminEmailsListQueryParamsHelper = ({ keyword, sort, filter }: GetEmailsListReqParams): AdminEmailsListQueryParams => {
  const params: AdminEmailsListQueryParams = {};

  if (filter) {
    params.filter_by = filter;
  }
  if (sort && Array.isArray(sort)) {
    params.sort_by = sort.join(' ');
  }
  if (keyword) {
    params.keyword = keyword;
  }

  return params;
};

export const getAdminSystemErrorsListQueryParamsHelper = ({ status }: GetErrorsListReqParams): AdminErrorsListQueryParams => {
  const params: AdminErrorsListQueryParams = {};

  if (status) {
    params.status_is = status;
  }

  return params;
};

export const getOverdueExportsListQueryParamsHelper = ({ keyword, sort }: GetOverdueListReqParams): OverdueListQueryParams => {
  const params: OverdueListQueryParams = {};

  if (sort && Array.isArray(sort)) {
    params.sort_by = sort.join(' ');
  }
  if (keyword) {
    params.keyword = keyword;
  }

  return params;
};

export const getDeliveriesListQueryParamsHelper = ({ keyword, filter, sort }: GetDeliveryListReqParams): DeliveryListQueryParams => {
  const params: DeliveryListQueryParams = {};
  params.delivery_date = filter?.delivery_date || format(new Date(), 'yyyy-MM-dd');
  if (sort && Array.isArray(sort) && !isEqual(sort, ['', 'asc'])) {
    params['q[sort_by]'] = sort.join(' ').toLowerCase();
  }

  if (keyword) {
    params['q[keyword]'] = keyword;
  }
  if (filter?.driver && filter.driver !== -1) {
    params.driver_id = filter?.driver;
  }
  if (filter?.delivery_status) {
    params['q[delivery_status]'] =
      filter.delivery_status.includes('-1') && filter.delivery_status.length === 1
        ? undefined
        : JSON.stringify(filter.delivery_status.filter((el) => el !== '-1').map((el) => +el));
  }
  return params;
};

export const getDriversListQueryParamsHelper = ({ keyword, sort }: GetDriversListReqParams): DriversListQueryParams => {
  const params: DriversListQueryParams = {};
  if (sort && Array.isArray(sort)) {
    params['q[sort_by]'] = sort.join(' ');
  }
  if (keyword) {
    params['q[keyword]'] = keyword;
  }
  return params;
};

export const getTeammtesListQueryParamsHelper = ({ keyword, sort }: GetTeammatesListReqParams): TeammatesListQueryParams => {
  const params: TeammatesListQueryParams = {};
  if (sort && Array.isArray(sort)) {
    params['q[sort_by]'] = sort.join(' ');
  }
  if (keyword) {
    params['q[keyword]'] = keyword;
  }
  return params;
};

export const getRecipesQueryParamsHelper = ({ keyword, sub_recipe, sortBy, mostSales }: GetRecipesRequest) => {
  const params: any = {};
  if (keyword) {
    params['q[keyword]'] = keyword;
  }
  if (mostSales) {
    params.most_sales = 'desc';
  }
  if (sortBy) {
    params['q[sort_by]'] = sortBy;
  }
  params.sub_recipe = sub_recipe;

  return params;
};

export const getInventoryProductsQueryParamsHelper = ({
  keyword,
  sort,
  status,
  supplierId,
  favourite,
  recently,
}: GetInventoryListReqParams): InventoryListQueryParams => {
  const params: InventoryListQueryParams = {};
  if (sort && Array.isArray(sort)) {
    params['q[sort_by]'] = sort.join(' ');
  }
  if (keyword) {
    params['q[keyword]'] = keyword;
  }
  if (keyword) {
    params['q[keyword]'] = keyword;
  }
  if (status !== undefined) {
    params['q[setup_status]'] = status;
  }
  if (supplierId) {
    params.supplier_id = supplierId;
  }
  if (recently) {
    params.recently_purchased = true;
  }
  if (favourite) {
    params['q[favourite]'] = true;
  }
  return params;
};

export const getInventorySSQueryParamsHelper = ({
  keyword,
  sort,
  sub_category_id,
}: GetSSInventoryListReqParams): SSInventoryListQueryParams => {
  const params: SSInventoryListQueryParams = {};
  if (sort && Array.isArray(sort)) {
    params['q[sort_by]'] = sort.join(' ');
  }
  if (keyword) {
    params['q[keyword]'] = keyword;
  }
  if (sub_category_id) {
    params['q[sub_category_id]'] = sub_category_id.toString();
  }
  return params;
};
